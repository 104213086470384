




















import {Component} from 'vue-property-decorator';

import AppComponent from '@/mixins/ComponentMixin.vue';
import {IVisit} from '@/interfaces/visits';

import AdminVisitTable from '@/components/tables/AdminVisitTable.vue';
import {dispatchGetVisitsForList} from '@/store/crud/actions';

@Component({components: {AdminVisitTable}})
export default class AdminVisits extends AppComponent {
  public visitsForTableList: IVisit[] = [];

  /**
   * fetchs all the needed data for the view
   * displays a loading screen while fetching data
   * create an object mixing visit and patient by id
   */
  public fetchingVisitsData = false;

  public async fetchData() {
    this.fetchingVisitsData = true;
    await this.$nextTick();
    this.visitsForTableList = await dispatchGetVisitsForList(this.$store);
    this.fetchingVisitsData = false;
  }

  public mounted() {
    this.fetchData();
  }

  public editVisit(visit: IVisit) {
    if (visit && visit.id) {
      this.$router.push({name: 'main-admin-visits-edit', params: {visitId: visit.id}});
    } else {
      // FIXME - important log here
      this.toast(`We couldn't update this Visit, please, try again later`, true);
    }
  }
}
