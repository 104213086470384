






























































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ConfirmationModal extends Vue {
  @Prop({ type: String }) public elementId: string;
  @Prop({ type: Boolean, default: false }) public listItem: boolean;
  @Prop({ type: String }) public buttonLabel: string;
  @Prop({ type: String }) public confirmationLabel1: string;
  @Prop({ type: String }) public confirmationLabel2: string;
  @Prop({ type: String }) public confirmationLabel3: string;
  @Prop({ type: String, default: 'Yes' }) public confirmButtonLabel: string;
  @Prop({ type: String, default: 'No' }) public cancelButtonLabel: string;
  @Prop({ type: String, default: 'Confirm delete?' }) public title: string;
  @Prop({ type: Number, default: 290 }) public modalWidth: number;
  @Prop({ type: Boolean, default: true }) public showDeleteWarning: boolean;
  @Prop({ type: String, default: 'delete' }) public icon: string;
  @Prop({ type: String, default: 'error' }) public iconColor: string;

  public dialog = false;

  public emitConfirm() {
    this.dialog = false;
    this.$emit('confirm');
  }
}
